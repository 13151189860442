import React from "react";
import Menu from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ArticleIcon from "@mui/icons-material/Article";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import BoldIcon from "@mui/icons-material/FormatBold";
import ItalicIcon from "@mui/icons-material/FormatItalic";
import UnderlineIcon from "@mui/icons-material/FormatUnderlined";
import CodeIcon from "@mui/icons-material/Code";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import BlockQuoteIcon from "@mui/icons-material/FormatQuote";
import NumberedListIcon from "@mui/icons-material/FormatListNumbered";
import BulletedListIcon from "@mui/icons-material/FormatListBulleted";
import AlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import AlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import AlignRightIcon from "@mui/icons-material/FormatAlignRight";
import AlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import ShortTextIcon from "@mui/icons-material/ShortText";
import ExpandIcon from "@mui/icons-material/Expand";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ListAltIcon from "@mui/icons-material/ListAlt";

const MenuIcon = (props) => {
  return <Menu style={{ fontSize: "40px", color: "#fff" }} />;
};

const Search = (props) => {
  return (
    <SearchIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};
const Add = (props) => {
  return (
    <AddIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const File = (props) => {
  return (
    <FileCopyIcon
      style={{ fontSize: props.iconSize, color: props.iconColor }}
    />
  );
};

const DetailsIcon = (props) => {
  return <ArticleIcon style={{ fontSize: props.size, color: props.color }} />;
};

const HomeMenuIcon = (props) => {
  return <HomeIcon style={{ fontSize: props.size, color: props.color }} />;
};

const DocumentMenuIcon = (props) => {
  return <NoteAddIcon style={{ fontSize: props.size, color: props.color }} />;
};

const ViewMenuIcon = (props) => {
  return (
    <RemoveRedEyeIcon style={{ fontSize: props.size, color: props.color }} />
  );
};

const DownloadIcon = (props) => {
  return (
    <CloudUploadIcon style={{ fontSize: props.size, color: props.color }} />
  );
};

const Edit = (props) => (
  <EditIcon style={{ fontSize: props.size, color: props.color }} />
);

const Close = (props) => (
  <CloseIcon style={{ fontSize: props.size, color: props.color }} />
);

const Check = (props) => (
  <CheckIcon style={{ fontSize: props.size, color: props.color }} />
);

const Bold = (props) => (
  <BoldIcon style={{ fontSize: props.size, color: props.color }} />
);

const Italic = (props) => (
  <ItalicIcon style={{ fontSize: props.size, color: props.color }} />
);

const Underline = (props) => (
  <UnderlineIcon style={{ fontSize: props.size, color: props.color }} />
);

const Code = (props) => (
  <CodeIcon style={{ fontSize: props.size, color: props.color }} />
);

const LooksOne = (props) => (
  <LooksOneIcon style={{ fontSize: props.size, color: props.color }} />
);

const LooksTwo = (props) => (
  <LooksTwoIcon style={{ fontSize: props.size, color: props.color }} />
);

const BlockQuote = (props) => (
  <BlockQuoteIcon style={{ fontSize: props.size, color: props.color }} />
);

const NumberedList = (props) => (
  <NumberedListIcon style={{ fontSize: props.size, color: props.color }} />
);

const BulletedList = (props) => (
  <BulletedListIcon style={{ fontSize: props.size, color: props.color }} />
);

const AlignLeft = (props) => (
  <AlignLeftIcon style={{ fontSize: props.size, color: props.color }} />
);

const AlignCenter = (props) => (
  <AlignCenterIcon style={{ fontSize: props.size, color: props.color }} />
);

const AlignRight = (props) => (
  <AlignRightIcon style={{ fontSize: props.size, color: props.color }} />
);

const AlignJustify = (props) => (
  <AlignJustifyIcon style={{ fontSize: props.size, color: props.color }} />
);

const Delete = (props) => (
  <DeleteIcon style={{ fontSize: props.size, color: props.color }} />
);

const AddCircle = (props) => (
  <AddCircleIcon style={{ fontSize: props.size, color: props.color }} />
);

const QuestionMark = (props) => (
  <QuestionMarkIcon style={{ fontSize: props.size, color: props.color }} />
);

const ReadMore = (props) => (
  <ReadMoreIcon style={{ fontSize: props.size, color: props.color }} />
);

const ShortText = (props) => (
  <ShortTextIcon style={{ fontSize: props.size, color: props.color }} />
);

const Expand = (props) => (
  <ExpandIcon style={{ fontSize: props.size, color: props.color }} />
);

const Summarize = (props) => (
  <SummarizeIcon style={{ fontSize: props.size, color: props.color }} />
);

const ListAlt = (props) => (
  <ListAltIcon style={{ fontSize: props.size, color: props.color }} />
);
export const Icon = ({ component, style, ...props }) =>
  component({
    style: {
      width: "33px",
      height: "33px",
      borderRadius: "10px",
      textAlign: "center",
      padding: "5px",
      boxShadow: "#819dadff 1px 2px 4px",
      ...style,
    },
    ...props,
  });

export const MENU_ICON = (props) => Icon({ component: MenuIcon, ...props });
export const HOME_ICON = (props) => Icon({ component: HomeMenuIcon, ...props });
export const DOCUMENT_MENU_ICON = (props) =>
  Icon({ component: DocumentMenuIcon, ...props });
export const VIEW_MENU_ICON = (props) =>
  Icon({ component: ViewMenuIcon, ...props });
export const UPLOAD_ICON = (props) =>
  Icon({ component: DownloadIcon, ...props });
export const DETAILS_ICON = (props) =>
  Icon({ component: DetailsIcon, ...props });
export const SEARCH_ICON = (props) => Icon({ component: Search, ...props });
export const ADD_ICON = (props) => Icon({ component: Add, ...props });
export const FILE_ICON = (props) => Icon({ component: File, ...props });
export const EDIT_ICON = (props) => Icon({ component: Edit, ...props });
export const CLOSE_ICON = (props) => Icon({ component: Close, ...props });
export const CHECK_ICON = (props) => Icon({ component: Check, ...props });
export const BOLD_ICON = (props) => Icon({ component: Bold, ...props });
export const ITALIC_ICON = (props) => Icon({ component: Italic, ...props });
export const UNDERLINE_ICON = (props) =>
  Icon({ component: Underline, ...props });
export const CODE_ICON = (props) => Icon({ component: Code, ...props });
export const LOOKS_ONE_ICON = (props) =>
  Icon({ component: LooksOne, ...props });
export const LOOKS_TWO_ICON = (props) =>
  Icon({ component: LooksTwo, ...props });
export const BLOCK_QUOTE_ICON = (props) =>
  Icon({ component: BlockQuote, ...props });
export const NUMBERED_LIST_ICON = (props) =>
  Icon({ component: NumberedList, ...props });
export const BULLETED_LIST_ICON = (props) =>
  Icon({ component: BulletedList, ...props });
export const ALIGN_LEFT_ICON = (props) =>
  Icon({ component: AlignLeft, ...props });
export const ALIGN_CENTER_ICON = (props) =>
  Icon({ component: AlignCenter, ...props });
export const ALIGN_RIGHT_ICON = (props) =>
  Icon({ component: AlignRight, ...props });
export const ALIGN_JUSTIFY_ICON = (props) =>
  Icon({ component: AlignJustify, ...props });
export const DELETE_ICON = (props) => Icon({ component: Delete, ...props });
export const ADD_CIRCLE_ICON = (props) =>
  Icon({ component: AddCircle, ...props });
export const QUESTION_MARK_ICON = (props) =>
  Icon({ component: QuestionMark, ...props });
export const READ_MORE_ICON = (props) =>
  Icon({ component: ReadMore, ...props });
export const SHORT_TEXT_ICON = (props) =>
  Icon({ component: ShortText, ...props });
export const EXPAND_ICON = (props) => Icon({ component: Expand, ...props });
export const SUMMARIZE_ICON = (props) =>
  Icon({ component: Summarize, ...props });
export const LIST_ALT_ICON = (props) => Icon({ component: ListAlt, ...props });
