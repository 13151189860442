import * as React from "react"
import IconButton from "@mui/material/IconButton"
import {
  Menu,
  MenuItem,
  Typography
} from "@mui/material/"
import {
  MENU_ICON,
  HOME_ICON,
  DOCUMENT_MENU_ICON,
  VIEW_MENU_ICON,
  DETAILS_ICON,
  SEARCH_ICON
} from "../assets/icons/IconList"


const ITEM_HEIGHT = 125;

export default function PrimaryMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const url = window.location.href

  return (
    <div className="menuContainer">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{marginTop: '-10px'}}
      >
        <MENU_ICON />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        className="menuItem"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: "225px",
            color: "#4299f7",
            paddingBottom: '0px',
            borderBottom: 'solid 2px #006bb6'
          },
          
        }}
      >
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/" ><HOME_ICON  color={"#006bb6" } size={"25px"} /> <Typography className="menuName" >&nbsp; Home</Typography></a> </MenuItem>
      {/* <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/createProposals" ><DOCUMENT_MENU_ICON  color={"#006bb6" } size={"25px"} /> <Typography className="menuName" >&nbsp; Create Proposals</Typography></a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/viewProposals" ><VIEW_MENU_ICON  color={"#006bb6" } size={"25px"} /> <Typography className="menuName" >&nbsp;View Proposals</Typography></a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/proposalDetails" ><DETAILS_ICON  color={"#006bb6" } size={"25px"} /> <Typography className="menuName" >&nbsp;Proposal Details</Typography></a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/nlpSearch" ><SEARCH_ICON  color={"#006bb6" } size={"25px"} /> <Typography className="menuName" >&nbsp;NLP Search</Typography></a> </MenuItem> */}

      </Menu>

    </div>
  );
}
