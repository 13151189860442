import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// GET FOIA Case data
const getFoiaCaseData = () => {
  return axios.get(`${BASE_URL}cases/`, { crossdomain: true });
};

// GET FOIA Case data filtered by case number
const getFoiaCaseByNumber = (number) => {
  return axios.get(`${BASE_URL}cases/${number}`, { crossdomain: true });
};

// GET Extracted Entities
const getCaseDetails = (text) => {
  return axios.get(
    `https://centcomservices.ais.saicds.com:8085/cases/${text}`,
    { crossdomain: true }
  );
};

// Search Page Query
const postQuerySearch = (data) => {
  const payload = {
    query_text: data.questionSearch,
    mode: data.mode,
    count: 5,
    index: "centcom",
    score_filter: 0.2,
  };

  // axios.put(`${BASE_URL}search/`, {

  //   query_text: "who used russian weapons",
  //   mode: "generative",
  //   count: 5,
  //   index: "centcom",
  //   score_filter: 0.2

  //   }).then(response => {
  //     console.log(response.data)
  //   })
  //   .catch(error => {
  //       console.log(error)
  //   })
};

// PUT redact
const postRedaction = () => {
  return axios.get(`${BASE_URL}redact/`, { crossdomain: true });
};

// POST new FOIA Case
const postNewFoiaCase = (data) => {
  console.log("Data: ", data.caseNumber);

  axios
    .post(`${BASE_URL}cases/`, {
      assigneduser: "Garret Dechellis",
      associatedentities: ["china", "winnie the poo"],
      casefiles: [
        "/files/17/Q3/BGRMA7AFGN020/F/TFP04/PAL_Share/Rapid Equipping Force (REF)/Lead Lab Engineer/LPWS/LPWS Drawings/8355321C.pdf8355321C.pdf",
      ],
      casenumber: data.caseNumber,
      receivedon: "1/5/2023",
      s3path: "s3://bucket-name/1115",
      status: "Hold",
      trackingcomments: "This request is pretty standard, etc. etc.",
    })
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

const postResponseOutline = async (formData) => {
  try {
    const response = await axios.post(
      "http://54.227.19.229:8000/rdo",
      formData,
      {
        headers: {
          Authorization:
            "Bearer eyJraWQiOiJwY0hOZnMxbVdscWR3S29xeFlWbkNLWnRKQVdmcm8rUms2cVd3akZBTDMwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2MDg3YjVmNC1kZTNkLTQ3N2UtODljZS1hZDMwNzA3MDVkNzQiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfNzNRN3dSSDJPX1NBSUMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNzNRN3dSSDJPIiwidmVyc2lvbiI6MiwiY2xpZW50X2lkIjoiNjdlNjlwbGM1OThmOW9wcDM4MzU4ZmJnN3MiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk4MjU1MTQyLCJleHAiOjE2OTg4OTg2MzEsImlhdCI6MTY5ODg2MjYzMSwianRpIjoiYmZhOGU2OTEtZjIzZS00YWIyLWE5OGItOWFmZWI0YTgwNTk1IiwidXNlcm5hbWUiOiJzYWljX2FkbmFuLmEuY2hvd2RodXJ5QHNhaWMuY29tIn0.5KzKu1i58FQTAHcD67GasNaU9MMkywcXAKhsU3waI87XzReP-bI7xnNOMV75ZDqb6mtit19HqyR3HQWbY0z3AnAIAdZc17qosL9yZcChKqvIE609OmTs8bPNe7IorS6lSh4HEuYFxl9LaZjSLOpIOBrdEPZwpOwlhVwOCQ2iMVCydd7AiWIp2AEbC8bd1HNFw1VrNzwNl4rayPA1-RYz_MdqsLbSI4SgVoB_NAUlOiMkOGoS0F9tC8hRm6iX3V6Rp3wOYf6cWoLX8A4mveFleHd4v67f44wQ9pLBBEeE_OC-FTnO3zIL9mJmkmz2jxT3ylmKqfq72Ft6fEyvuR34Rg",
          "Content-type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    return response;
  } catch (e) {
    throw e;
  }
};

const postGenerateResponse = async (
  generatedResponseOutline = [],
  params = { grade_level: 10, active_voice: true }
) => {
  try {
    const response = await axios.post(
      "http://54.227.19.229:8000/response",
      generatedResponseOutline,
      {
        params,
        headers: {
          Authorization:
            "Bearer eyJraWQiOiJwY0hOZnMxbVdscWR3S29xeFlWbkNLWnRKQVdmcm8rUms2cVd3akZBTDMwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2MDg3YjVmNC1kZTNkLTQ3N2UtODljZS1hZDMwNzA3MDVkNzQiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfNzNRN3dSSDJPX1NBSUMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNzNRN3dSSDJPIiwidmVyc2lvbiI6MiwiY2xpZW50X2lkIjoiNjdlNjlwbGM1OThmOW9wcDM4MzU4ZmJnN3MiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk4MjU1MTQyLCJleHAiOjE2OTg4OTg2MzEsImlhdCI6MTY5ODg2MjYzMSwianRpIjoiYmZhOGU2OTEtZjIzZS00YWIyLWE5OGItOWFmZWI0YTgwNTk1IiwidXNlcm5hbWUiOiJzYWljX2FkbmFuLmEuY2hvd2RodXJ5QHNhaWMuY29tIn0.5KzKu1i58FQTAHcD67GasNaU9MMkywcXAKhsU3waI87XzReP-bI7xnNOMV75ZDqb6mtit19HqyR3HQWbY0z3AnAIAdZc17qosL9yZcChKqvIE609OmTs8bPNe7IorS6lSh4HEuYFxl9LaZjSLOpIOBrdEPZwpOwlhVwOCQ2iMVCydd7AiWIp2AEbC8bd1HNFw1VrNzwNl4rayPA1-RYz_MdqsLbSI4SgVoB_NAUlOiMkOGoS0F9tC8hRm6iX3V6Rp3wOYf6cWoLX8A4mveFleHd4v67f44wQ9pLBBEeE_OC-FTnO3zIL9mJmkmz2jxT3ylmKqfq72Ft6fEyvuR34Rg",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

const postSimplifyText = async ({ query = "", n = 1 }) => {
  try {
    const response = await axios.post(
      "http://54.227.19.229:8000/simplify",
      {
        query,
        n,
      },
      {
        headers: {
          Authorization:
            "Bearer eyJraWQiOiJwY0hOZnMxbVdscWR3S29xeFlWbkNLWnRKQVdmcm8rUms2cVd3akZBTDMwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2MDg3YjVmNC1kZTNkLTQ3N2UtODljZS1hZDMwNzA3MDVkNzQiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfNzNRN3dSSDJPX1NBSUMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNzNRN3dSSDJPIiwidmVyc2lvbiI6MiwiY2xpZW50X2lkIjoiNjdlNjlwbGM1OThmOW9wcDM4MzU4ZmJnN3MiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk4MjU1MTQyLCJleHAiOjE2OTg4OTg2MzEsImlhdCI6MTY5ODg2MjYzMSwianRpIjoiYmZhOGU2OTEtZjIzZS00YWIyLWE5OGItOWFmZWI0YTgwNTk1IiwidXNlcm5hbWUiOiJzYWljX2FkbmFuLmEuY2hvd2RodXJ5QHNhaWMuY29tIn0.5KzKu1i58FQTAHcD67GasNaU9MMkywcXAKhsU3waI87XzReP-bI7xnNOMV75ZDqb6mtit19HqyR3HQWbY0z3AnAIAdZc17qosL9yZcChKqvIE609OmTs8bPNe7IorS6lSh4HEuYFxl9LaZjSLOpIOBrdEPZwpOwlhVwOCQ2iMVCydd7AiWIp2AEbC8bd1HNFw1VrNzwNl4rayPA1-RYz_MdqsLbSI4SgVoB_NAUlOiMkOGoS0F9tC8hRm6iX3V6Rp3wOYf6cWoLX8A4mveFleHd4v67f44wQ9pLBBEeE_OC-FTnO3zIL9mJmkmz2jxT3ylmKqfq72Ft6fEyvuR34Rg",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    return response.data;
  } catch (e) {
    console.log("E:", e);
    throw e;
  }
};

const postExpandText = async ({ query = "", n = 1 }) => {
  try {
    const response = await axios.post(
      "http://54.227.19.229:8000/expand",
      {
        query,
        n,
      },
      {
        headers: {
          Authorization:
            "Bearer eyJraWQiOiJwY0hOZnMxbVdscWR3S29xeFlWbkNLWnRKQVdmcm8rUms2cVd3akZBTDMwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2MDg3YjVmNC1kZTNkLTQ3N2UtODljZS1hZDMwNzA3MDVkNzQiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfNzNRN3dSSDJPX1NBSUMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNzNRN3dSSDJPIiwidmVyc2lvbiI6MiwiY2xpZW50X2lkIjoiNjdlNjlwbGM1OThmOW9wcDM4MzU4ZmJnN3MiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk4MjU1MTQyLCJleHAiOjE2OTg4OTg2MzEsImlhdCI6MTY5ODg2MjYzMSwianRpIjoiYmZhOGU2OTEtZjIzZS00YWIyLWE5OGItOWFmZWI0YTgwNTk1IiwidXNlcm5hbWUiOiJzYWljX2FkbmFuLmEuY2hvd2RodXJ5QHNhaWMuY29tIn0.5KzKu1i58FQTAHcD67GasNaU9MMkywcXAKhsU3waI87XzReP-bI7xnNOMV75ZDqb6mtit19HqyR3HQWbY0z3AnAIAdZc17qosL9yZcChKqvIE609OmTs8bPNe7IorS6lSh4HEuYFxl9LaZjSLOpIOBrdEPZwpOwlhVwOCQ2iMVCydd7AiWIp2AEbC8bd1HNFw1VrNzwNl4rayPA1-RYz_MdqsLbSI4SgVoB_NAUlOiMkOGoS0F9tC8hRm6iX3V6Rp3wOYf6cWoLX8A4mveFleHd4v67f44wQ9pLBBEeE_OC-FTnO3zIL9mJmkmz2jxT3ylmKqfq72Ft6fEyvuR34Rg",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    return response.data;
  } catch (e) {
    console.log("E:", e);
    throw e;
  }
};

const postTextToList = async ({ query = "", n = 1 }) => {
  try {
    const response = await axios.post(
      "http://54.227.19.229:8000/to_list",
      {
        query,
        n,
      },
      {
        headers: {
          Authorization:
            "Bearer eyJraWQiOiJwY0hOZnMxbVdscWR3S29xeFlWbkNLWnRKQVdmcm8rUms2cVd3akZBTDMwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2MDg3YjVmNC1kZTNkLTQ3N2UtODljZS1hZDMwNzA3MDVkNzQiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfNzNRN3dSSDJPX1NBSUMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNzNRN3dSSDJPIiwidmVyc2lvbiI6MiwiY2xpZW50X2lkIjoiNjdlNjlwbGM1OThmOW9wcDM4MzU4ZmJnN3MiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk4MjU1MTQyLCJleHAiOjE2OTg4OTg2MzEsImlhdCI6MTY5ODg2MjYzMSwianRpIjoiYmZhOGU2OTEtZjIzZS00YWIyLWE5OGItOWFmZWI0YTgwNTk1IiwidXNlcm5hbWUiOiJzYWljX2FkbmFuLmEuY2hvd2RodXJ5QHNhaWMuY29tIn0.5KzKu1i58FQTAHcD67GasNaU9MMkywcXAKhsU3waI87XzReP-bI7xnNOMV75ZDqb6mtit19HqyR3HQWbY0z3AnAIAdZc17qosL9yZcChKqvIE609OmTs8bPNe7IorS6lSh4HEuYFxl9LaZjSLOpIOBrdEPZwpOwlhVwOCQ2iMVCydd7AiWIp2AEbC8bd1HNFw1VrNzwNl4rayPA1-RYz_MdqsLbSI4SgVoB_NAUlOiMkOGoS0F9tC8hRm6iX3V6Rp3wOYf6cWoLX8A4mveFleHd4v67f44wQ9pLBBEeE_OC-FTnO3zIL9mJmkmz2jxT3ylmKqfq72Ft6fEyvuR34Rg",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    return response.data;
  } catch (e) {
    console.log("E:", e);
    throw e;
  }
};

const postTextToNarrative = async ({ query = "", n = 1 }) => {
  try {
    const response = await axios.post(
      "http://54.227.19.229:8000/to_narrative",
      {
        query,
        n,
      },
      {
        headers: {
          Authorization:
            "Bearer eyJraWQiOiJwY0hOZnMxbVdscWR3S29xeFlWbkNLWnRKQVdmcm8rUms2cVd3akZBTDMwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2MDg3YjVmNC1kZTNkLTQ3N2UtODljZS1hZDMwNzA3MDVkNzQiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfNzNRN3dSSDJPX1NBSUMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfNzNRN3dSSDJPIiwidmVyc2lvbiI6MiwiY2xpZW50X2lkIjoiNjdlNjlwbGM1OThmOW9wcDM4MzU4ZmJnN3MiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk4MjU1MTQyLCJleHAiOjE2OTg4OTg2MzEsImlhdCI6MTY5ODg2MjYzMSwianRpIjoiYmZhOGU2OTEtZjIzZS00YWIyLWE5OGItOWFmZWI0YTgwNTk1IiwidXNlcm5hbWUiOiJzYWljX2FkbmFuLmEuY2hvd2RodXJ5QHNhaWMuY29tIn0.5KzKu1i58FQTAHcD67GasNaU9MMkywcXAKhsU3waI87XzReP-bI7xnNOMV75ZDqb6mtit19HqyR3HQWbY0z3AnAIAdZc17qosL9yZcChKqvIE609OmTs8bPNe7IorS6lSh4HEuYFxl9LaZjSLOpIOBrdEPZwpOwlhVwOCQ2iMVCydd7AiWIp2AEbC8bd1HNFw1VrNzwNl4rayPA1-RYz_MdqsLbSI4SgVoB_NAUlOiMkOGoS0F9tC8hRm6iX3V6Rp3wOYf6cWoLX8A4mveFleHd4v67f44wQ9pLBBEeE_OC-FTnO3zIL9mJmkmz2jxT3ylmKqfq72Ft6fEyvuR34Rg",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    return response.data;
  } catch (e) {
    console.log("E:", e);
    throw e;
  }
};

export {
  getFoiaCaseData,
  getCaseDetails,
  getFoiaCaseByNumber,
  postNewFoiaCase,
  postRedaction,
  postQuerySearch,
  postResponseOutline,
  postGenerateResponse,
  postSimplifyText,
  postExpandText,
  postTextToList,
  postTextToNarrative,
};
