import React from "react";
import { Grid } from "@mui/material/";
import ProposalResponseContent from "./content/proposalResponseContent";
import ProposalDetailsProvider from "./contexts/proposalDetails";

export default function ProposalResponse() {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12}>
      <Grid item xs={12} className="marginCenter">
        <ProposalDetailsProvider>
          <ProposalResponseContent />
        </ProposalDetailsProvider>
        <div className="spacer50" />
      </Grid>
    </Grid>
  );
}
