import React, { createContext, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { postGenerateResponse } from "../service/services";

export const ProposalDetailsContext = createContext(null);

const ProposalDetailsProvider = ({ children }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [proposalDetails, setProposalDetails] = useState(
    state?.proposalDetails
  );
  const [reqLabelToEdit, setReqLabelToEdit] = useState("");
  const [editedLabel, setEditedLabel] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatingError, setGeneratingError] = useState(null);
  const [activeEditedSection, setActiveEditedSection] = useState("");
  const [newRequirementText, setNewRequirementText] = useState("");
  const [reqLabelToDelete, setReqLabelToDelete] = useState("");
  const [showDeleteRequirementDialog, setShowDeleteRequirementDialog] =
    useState(false);

  // Edit Label methods
  const onChangeOutlineLabel = (e) => setEditedLabel(e.target.value);


  const onSetReqLabelToEdit = (reqLabel) => {
    setReqLabelToEdit(reqLabel);
    setEditedLabel(reqLabel);
  };

  const onResetLabelToEdit = () => {
    setReqLabelToEdit("");
    setEditedLabel("");
  };

  const onUpdateOutlineLabel = useCallback(() => {
    const { outline } = proposalDetails;
    const updatedProposalDetails = {
      ...proposalDetails,
      outline: {
        sections: outline.sections.map((item) => ({
          ...item,
          requirements: item.requirements.map((req) =>
            req.label === reqLabelToEdit ? { ...req, label: editedLabel } : req
          ),
        })),
      },
    };

    setProposalDetails(updatedProposalDetails);
    setReqLabelToEdit("");
    setEditedLabel("");
  }, [proposalDetails, reqLabelToEdit, editedLabel]);

  // Delete label methods
  const onDeleteOutlineRequirement = (reqLabel) => {
    setReqLabelToDelete(reqLabel);
    setShowDeleteRequirementDialog(true);
  };

  const onCloseDeleteRequirementDialog = () =>
    setShowDeleteRequirementDialog(false);

  const onConfirmOutlineRequirementDelete = useCallback(() => {
    const { outline } = proposalDetails;
    const updatedProposalDetails = {
      ...proposalDetails,
      outline: {
        sections: outline.sections.map((item) => ({
          ...item,
          requirements: item.requirements.filter(
            (req) => req.label !== reqLabelToDelete
          ),
        })),
      },
    };

    setProposalDetails(updatedProposalDetails);
    setShowDeleteRequirementDialog(false);
    setReqLabelToDelete("");
  }, [proposalDetails, reqLabelToDelete, showDeleteRequirementDialog]);

  // Add label methods
  const onResetNewRequirementText = () => {
    setActiveEditedSection("");
    setNewRequirementText("");
  };

  const onAddNewRequirement = useCallback(() => {
    const { outline } = proposalDetails;
    const outlineItemSectionIdx = outline.sections.findIndex(
      (outlineSectionItem) => outlineSectionItem.header === activeEditedSection
    );

    const outlineSectionItem = outline.sections[outlineItemSectionIdx];

    const newRequirementItem = { label: newRequirementText, text_source: "" };

    const updatedOutlineSectionItem = {
      ...outlineSectionItem,
      requirements: [newRequirementItem, ...outlineSectionItem.requirements],
    };

    const updatedProposalDetails = {
      ...proposalDetails,
      outline: {
        sections: [
          ...outline.sections.slice(0, outlineItemSectionIdx),
          updatedOutlineSectionItem,
          ...outline.sections.slice(outlineItemSectionIdx + 1),
        ],
      },
    };

    setProposalDetails(updatedProposalDetails);
    setActiveEditedSection("");
    setNewRequirementText("");
  }, [newRequirementText, proposalDetails, activeEditedSection]);

  const onGenerateResponse = async () => {
    if (generatingError) {
      setGeneratingError(null);
    }

    const outlineSections = proposalDetails.outline.sections

    const gradeLvl = 10;
    const activeVoice = true;

    const params = {
      grade_level: gradeLvl,
      active_voice: activeVoice,
    };

    setIsGenerating(true);
    try {
      const response = await postGenerateResponse(outlineSections, params);

      navigate("/generatedResponse", {
        state: {
          generatedOutline: response,
        },
      });
    } catch (e) {
      setGeneratingError(e);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <ProposalDetailsContext.Provider
      value={{
        proposalDetails,
        reqLabelToEdit,
        setReqLabelToEdit,
        editedLabel,
        setEditedLabel,
        onSetReqLabelToEdit,
        onResetLabelToEdit,
        onChangeOutlineLabel,
        onUpdateOutlineLabel,
        onDeleteOutlineRequirement,
        reqLabelToDelete,
        showDeleteRequirementDialog,
        onCloseDeleteRequirementDialog,
        onConfirmOutlineRequirementDelete,
        activeEditedSection,
        setActiveEditedSection,
        newRequirementText,
        setNewRequirementText,
        onResetNewRequirementText,
        onAddNewRequirement,
        onGenerateResponse,
        isGenerating,
        generatingError,
      }}
    >
      {children}
    </ProposalDetailsContext.Provider>
  );
};

export default ProposalDetailsProvider;
