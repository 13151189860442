import React from "react"
import { Grid } from "@mui/material"
import SaicLogo from "../assets/images/Saic-Logo-White.png"
import MenuComponent from "../components/menu"

const Header = () => {
  return (

    <div >
      <Grid container xs={12} sm={12} md={12} lg={12}>
      
      <Grid item xs={1} >
          <div class="line right">
                <div class="scanner"></div>
            </div>
        </Grid>
        
        <Grid item xs={1} className="fadePanel" >
          
          <a href={"/"}>
            <img src={SaicLogo} alt="SAIC Logo"  />
         
          </a>
    
          
        </Grid>
      

        <Grid xs={10} className="rightAlign" >
        
          <MenuComponent />

        </Grid>
      
      </Grid>
    </div>
  )
}

export default Header
