import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { pdfjs } from "react-pdf";
import { StyledEngineProvider } from "@mui/material/styles";
import AppWrapper from "./template/appTemplate";
import Home from "./Home";
import CreateProposal from "./CreateProposal";
import ViewProposal from "./ViewProposals";
import ProposalDetails from "./ProposalDetails";
import ProposalResponse from "./ProposalResponse";
import PromptLLM from "./PromptLLM";
import GeneratedResponse from "./GeneratedResponse";
import NlpSearch from "./NlpSearch"

import "./assets/sheets/main.scss";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="appContainer">
        <AppWrapper>
          <BrowserRouter>
            <Routes>
              {/* <Route path="/" exact element={<Home />} /> */}
              <Route
                path="/"
                exact
                element={<CreateProposal />}
              />
              {/* <Route path="/viewProposals" exact element={<ViewProposal />} /> */}
              {/* <Route
                path="/proposalDetails/:id"
                exact
                element={<ProposalDetails />}
              /> */}
              <Route
                path="/proposalResponse"
                exact
                element={<ProposalResponse />}
              />
              {/* <Route path="/promptLLM" exact element={<PromptLLM />} /> */}
              <Route path="/generatedResponse" exact element={<GeneratedResponse/>}/>
              {/* <Route
                path="/nlpSearch"
                exact
                element={<NlpSearch />}
              /> */}
            </Routes>
          </BrowserRouter>
        </AppWrapper>
      </div>
    </StyledEngineProvider>
  );
}

export default App;
