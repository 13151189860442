import React, { useRef, useEffect } from "react";
import { useSlate, useFocused } from "slate-react";
import { Editor, Range } from "slate";
import { Toolbar, Portal } from "@mui/material";

export default function HoveringToolbar({ children }) {
  const toolbarRef = useRef(null);
  const editor = useSlate();
  const inFocus = useFocused();

  useEffect(() => {
    const el = toolbarRef.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      el.removeAttribute("style");
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();

    el.style.opacity = "1";
    el.style.top = `${rect.top + window.scrollY - el.offsetHeight}px`;
    el.style.left = `${
      rect.left + window.scrollX - el.offsetWidth / 2 + rect.width / 2
    }px`;
  }, [inFocus, editor?.selection, toolbarRef.current]);

  return (
    <Portal>
      <Toolbar className="hovering-toolbar" ref={toolbarRef}>
        {children}
      </Toolbar>
    </Portal>
  );
}
