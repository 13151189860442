import React, { useMemo, useCallback, useState } from "react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  Range,
} from "slate";
import { withReact, Editable, Slate, useSlate } from "slate-react";
import { withHistory } from "slate-history";
import {
  Toolbar,
  IconButton,
  Tooltip,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import isHotkey from "is-hotkey";
import clsx from "clsx";

import {
  BOLD_ICON,
  ITALIC_ICON,
  UNDERLINE_ICON,
  CODE_ICON,
  LOOKS_ONE_ICON,
  LOOKS_TWO_ICON,
  BLOCK_QUOTE_ICON,
  NUMBERED_LIST_ICON,
  BULLETED_LIST_ICON,
  ALIGN_LEFT_ICON,
  ALIGN_CENTER_ICON,
  ALIGN_RIGHT_ICON,
  ALIGN_JUSTIFY_ICON,
  SUMMARIZE_ICON,
  SHORT_TEXT_ICON,
  EXPAND_ICON,
  LIST_ALT_ICON,
} from "../assets/icons/IconList";

import HoveringToolbar from "./hoveringToolbar";
import RichTextEditorConfirmationModal from "./modals/richTextEditorConfirmationDialog";

import {
  postSimplifyText,
  postExpandText,
  postTextToList,
  postTextToNarrative,
} from "../service/services";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const markButtons = [
  {
    format: "bold",
    icon: BOLD_ICON,
    tooltipLabel: "Bold",
  },
  {
    format: "italic",
    icon: UNDERLINE_ICON,
    tooltipLabel: "Italic",
  },
  {
    format: "code",
    icon: CODE_ICON,
    tooltipLabel: "Code",
  },
  {
    format: "shorten",
    icon: SHORT_TEXT_ICON,
    tooltipLabel: "Shorten Text",
    asyncFunc: postSimplifyText,
  },
  {
    format: "narrative",
    icon: SUMMARIZE_ICON,
    tooltipLabel: "Narrative",
    asyncFunc: postTextToNarrative,
  },
  {
    format: "expand",
    icon: EXPAND_ICON,
    tooltipLabel: "Expand Text",
    asyncFunc: postExpandText,
  },
  {
    format: "list",
    icon: LIST_ALT_ICON,
    tooltipLabel: "List",
    asyncFunc: postTextToList,
  },
];

const blockButtons = [
  {
    format: "heading-one",
    icon: LOOKS_ONE_ICON,
    tooltipLabel: "Heading 1",
  },
  {
    format: "heading-two",
    icon: LOOKS_TWO_ICON,
    tooltipLabel: "Heading 2",
  },
  {
    format: "block-quote",
    icon: BLOCK_QUOTE_ICON,
    tooltipLabel: "Block Quote",
  },
  {
    format: "numbered-list",
    icon: NUMBERED_LIST_ICON,
    tooltipLabel: "Number List",
  },
  {
    format: "bulleted-list",
    icon: BULLETED_LIST_ICON,
    tooltipLabel: "Narrative",
    asyncFunc: postTextToNarrative,
  },
  {
    format: "left",
    icon: ALIGN_LEFT_ICON,
    tooltipLabel: "Align Left",
  },
  {
    format: "center",
    icon: ALIGN_CENTER_ICON,
    tooltipLabel: "Align Center",
  },
  {
    format: "right",
    icon: ALIGN_RIGHT_ICON,
    tooltipLabel: "Align Right",
  },
  {
    format: "justify",
    icon: ALIGN_JUSTIFY_ICON,
    tooltipLabel: "Align Justify",
  },
];

export default function RichTextEditor({ initialTextEditorValue = [] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalProps, setModalProps] = useState(null);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align };
    switch (element.type) {
      case "block-quote":
        return (
          <blockquote
            style={{
              color: "#aaa",
              fontStyle: "italic",
              borderLeftColor: "grey",
              borderLeftWidth: 2,
              borderLeftStyle: "solid",
              paddingLeft: 10,
            }}
            {...attributes}
          >
            {children}
          </blockquote>
        );
      case "bulleted-list":
        return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
        );
      case "heading-one":
        return (
          <h1 style={style} {...attributes}>
            {children}
          </h1>
        );
      case "heading-two":
        return (
          <h2 style={style} {...attributes}>
            {children}
          </h2>
        );
      case "list-item":
        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        );
      case "numbered-list":
        return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
        );
      default:
        return (
          <p style={style} {...attributes}>
            {children}
          </p>
        );
    }
  };
  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.code) {
      children = <code style={{ backgroundColor: "#eee" }}>{children}</code>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  const isBlockActive = (editor, format, blockType = "type") => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format,
      })
    );

    return !!match;
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      };
    }
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const BlockButton = ({ format, icon, tooltipLabel }) => {
    const editor = useSlate();
    return (
      <Tooltip title={tooltipLabel}>
        <IconButton
          className={clsx("richTextEditorToolbarButton", {
            richTextEditorBtnActive: isBlockActive(
              editor,
              format,
              TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
            ),
          })}
          onMouseDown={(event) => {
            event.preventDefault();
            toggleBlock(editor, format);
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  };

  const MarkButton = ({ format, icon, tooltipLabel, asyncFunc }) => {
    const editor = useSlate();
    return (
      <Tooltip title={tooltipLabel}>
        <IconButton
          className={clsx("richTextEditorToolbarButton", {
            richTextEditorBtnActive: isMarkActive(editor, format),
          })}
          onMouseDown={(event) => {
            event.preventDefault();
            toggleMark(editor, format);
            if (asyncFunc) handleAsyncTextTransformation(asyncFunc);
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  };

  const replaceText = (newText = "") => {
    const { selection } = editor;
    const [start, end] = Range.edges(selection);
    const startOffset = Editor.before(editor, start, { unit: "character" });
    const endOffset = Editor.after(editor, end, { unit: "character" });
    const targetRange = { anchor: startOffset, focus: endOffset };

    // Delete the selected text
    Transforms.delete(editor, { at: targetRange });

    // Insert the new text at the selection point
    Transforms.insertText(editor, newText, { at: startOffset });
  };

  const handleAsyncTextTransformation = useCallback(
    async (transformationFunc) => {
      const { selection } = editor;
      const selectionText = Editor.string(editor, selection);

      if (!selectionText) return;

      setIsLoading(true);

      try {
        const postData = {
          query: selectionText,
        };

        const response = await transformationFunc(postData);
        const modifiedText = response.modified_text[0];
        setShowConfirmationModal(true);
        setModalProps({
          handleClose: () => setShowConfirmationModal(false),
          onConfirm: () => {
            setShowConfirmationModal(false);
            replaceText(modifiedText);
          },
          originalText: selectionText,
          modifiedText,
        });
      } catch (e) {
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [modalProps, showConfirmationModal]
  );

  return (
    <Slate editor={editor} initialValue={initialTextEditorValue}>
      {showConfirmationModal && (
        <RichTextEditorConfirmationModal {...modalProps} />
      )}
      <Backdrop open={isLoading} sx={{ zIndex: 1000, color: "#FFF" }}>
        <CircularProgress color="inherit" size={70} />
      </Backdrop>
      <HoveringToolbar>
        {markButtons.map((markBtn) => (
          <MarkButton
            icon={<markBtn.icon />}
            format={markBtn.format}
            tooltipLabel={markBtn.tooltipLabel}
            asyncFunc={markBtn.asyncFunc}
          />
        ))}
        {blockButtons.map((blockBtn) => (
          <BlockButton
            icon={<blockBtn.icon />}
            format={blockBtn.format}
            tooltipLabel={blockBtn.tooltipLabel}
          />
        ))}
      </HoveringToolbar>
      <Toolbar className="richTextEditorToolbar">
        {markButtons.map((markBtn) => (
          <MarkButton
            icon={<markBtn.icon />}
            format={markBtn.format}
            tooltipLabel={markBtn.tooltipLabel}
            asyncFunc={markBtn.asyncFunc}
          />
        ))}
        {blockButtons.map((blockBtn) => (
          <BlockButton
            icon={<blockBtn.icon />}
            format={blockBtn.format}
            tooltipLabel={blockBtn.tooltipLabel}
          />
        ))}
      </Toolbar>
      <Editable
        style={{
          backgroundColor: "#FFF",
          paddingLeft: 8,
          paddingRight: 8,
          borderRadius: 4,
          marginBottom: 20,
          width: "100%",
        }}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        autoFocus
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
}
