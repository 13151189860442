import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

import RichTextEditor from '../components/richTextEditor';


export default function GeneratedResponseContent() {

    const { state } = useLocation();

    const generatedOutline = state?.generatedOutline
    
    const splitResponse = useMemo(() => generatedOutline.response.split(/\r\n|\r|\n/), [state])

    const initialTextEditorValue = useMemo(() => splitResponse.map(textItem => ({
        type: 'paragraph',
        children: [{ text: textItem }]
    })), [splitResponse])

    return (
        <Grid className="createProposalsContainer" style={{ alignItems: 'flex-start' }}>
            <Grid xs={12} className="pageTitle" style={{ marginTop: 0, paddingTop: 10, paddingLeft: 10 }}>
                Generated Response
            </Grid>
            <Grid
                xs={8}
                className="marginCenter"
                container
            >
                <RichTextEditor initialTextEditorValue={initialTextEditorValue} />
            </Grid>

        </Grid>
    )
}