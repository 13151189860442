import React from "react";
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function DeleteRequirementDialog({
  open,
  handleClose,
  onConfirm,
  onCancel,
  requirementLabel,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-outline-requirement"
      aria-describedby="confirm-outline-requirement-deletion"
    >
      <DialogTitle>Remove Outline Requirement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confirming will remove the following requirement from the outline:{" "}
          <b>{requirementLabel}</b>
        </DialogContentText>
        <DialogActions>
          <Button variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
