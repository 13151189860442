import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import clsx from "clsx";
import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Document, Page, pdfjs } from "react-pdf";

import { useLocation, useNavigate } from "react-router-dom";

import {
  EDIT_ICON,
  CLOSE_ICON,
  CHECK_ICON,
  DELETE_ICON,
  ADD_CIRCLE_ICON,
} from "../assets/icons/IconList";
import DeleteRequirementDialog from "../components/modals/deleteRequirementDialog";

import { ProposalDetailsContext } from "../contexts/proposalDetails";

function getTextItemWithNeighbors(textItems, itemIndex, span = 5) {
  return textItems
    .slice(Math.max(0, itemIndex - span), itemIndex + 1 + span)
    .filter(Boolean)
    .map((item) => item.str.trim())
    .join(" ");
}

function getIndexRange(string, substring) {
  const indexStart = string.indexOf(substring);
  const indexEnd = indexStart + substring.length;

  return [indexStart, indexEnd];
}

function escapedRegExp(pattern) {
  return pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export default function ProposalResponseContent() {
  const pdfDocumentRef = useRef();
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    proposalDetails,
    reqLabelToEdit,
    editedLabel,
    onSetReqLabelToEdit,
    onResetLabelToEdit,
    onChangeOutlineLabel,
    onUpdateOutlineLabel,
    onDeleteOutlineRequirement,
    reqLabelToDelete,
    showDeleteRequirementDialog,
    onCloseDeleteRequirementDialog,
    onConfirmOutlineRequirementDelete,
    activeEditedSection,
    setActiveEditedSection,
    newRequirementText,
    setNewRequirementText,
    onResetNewRequirementText,
    onAddNewRequirement,
    onGenerateResponse,
    isGenerating,
    generatingError,
  } = useContext(ProposalDetailsContext);

  const [searchText, setSearchText] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [textItems, setTextItems] = useState(null);
  const [LLMModel, setLLModel] = useState("");

  const acceptedFiles = state?.acceptedFiles;

  console.log("Accepted Files", acceptedFiles);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const onPageLoadSuccess = useCallback(async (page) => {
    const textContent = await page.getTextContent();

    setTextItems(textContent.items);
  }, []);

  const highlightPattern = useCallback(
    (text, pattern) => {
      const escapedPattern = escapedRegExp(pattern);
      const patternRegex = new RegExp(`(${escapedPattern})`, "gi");

      return text.replace(
        patternRegex,
        (value) =>
          `<mark style="color: #FFF; background-color: #006bb6; border-radius: 3px;">${value}</mark>`
      );
    },
    [searchText]
  );

  const textRenderer = useCallback(
    (textItem) => {
      if (!textItems) {
        return;
      }
      const { itemIndex } = textItem;

      const matchInTextItem = textItem.str.match(searchText);

      if (matchInTextItem) {
        // Found full match within current item, no need for black magic
        return highlightPattern(textItem.str, searchText);
      }

      // Full match within current item not found, let's check if we can find it

      const textItemWithNeighbors = getTextItemWithNeighbors(
        textItems,
        itemIndex
      );

      const matchInTextItemWithNeighbors = textItemWithNeighbors.match(
        `(${escapedRegExp(searchText)})`
      );

      if (!matchInTextItemWithNeighbors) {
        // No match
        return textItem.str;
      }

      // Now we need to figure out if the match we found was at least partially
      // in the line we're currently rendering
      const [matchIndexStart, matchIndexEnd] = getIndexRange(
        textItemWithNeighbors,
        searchText
      );
      const [textItemIndexStart, textItemIndexEnd] = getIndexRange(
        textItemWithNeighbors,
        textItem.str
      );

      if (
        // Match entirely in the previous line
        matchIndexEnd < textItemIndexStart ||
        // Match entirely in the next line
        matchIndexStart > textItemIndexEnd
      ) {
        return textItem.str;
      }

      // Match found was partially in the line we're currently rendering. Now
      // we need to figure out what does "partially" exactly mean

      // Find partial match in a line
      const indexOfCurrentTextItemInMergedLines = textItemWithNeighbors.indexOf(
        textItem.str
      );

      const matchIndexStartInTextItem = Math.max(
        0,
        matchIndexStart - indexOfCurrentTextItemInMergedLines
      );
      const matchIndexEndInTextItem =
        matchIndexEnd - indexOfCurrentTextItemInMergedLines;

      const partialStringToHighlight = textItem.str.slice(
        matchIndexStartInTextItem,
        matchIndexEndInTextItem
      );

      return highlightPattern(textItem.str, partialStringToHighlight);
    },
    [searchText, textItems]
  );

  const changePage = (offset) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset);

  const onGoToPreviousPage = () => changePage(-1);

  const onGoToNextPage = () => changePage(1);

  const handleKeywordFilter = useCallback(async () => {
    try {
      const pdfDataUrl = URL.createObjectURL(acceptedFiles[0]);
      const pdf = await pdfjs.getDocument(pdfDataUrl).promise;

      const resultPages = [];

      for (let i = 1; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const text = textContent.items.map((s) => s.str).join(" ");

        if (text.toLowerCase().includes(searchText.toLowerCase())) {
          resultPages.push(i);
          break;
        }
      }

      setPageNumber(resultPages[0] || 1);
    } catch (e) {
      console.log("Error filtering keyword result:", e);
    }
  }, [acceptedFiles, searchText]);

  const onBackToUpload = () => navigate("/");

  const onKeywordSelected = (searchKeyword) => {
    if (searchKeyword) setSearchText(searchKeyword);
    pdfDocumentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  useEffect(() => {
    if (searchText) handleKeywordFilter();
  }, [searchText, handleKeywordFilter]);

  useEffect(() => {
    if (!acceptedFiles || !proposalDetails) {
      return navigate("/createProposals");
    }
  }, [acceptedFiles, proposalDetails, navigate]);

  return (
    <Grid container xs={12} className="createProposalsContainer">
      <Grid item xs={12} className="pageTitle">
        RFI Details
      </Grid>
      {generatingError && (
        <Grid item xs={12}>
          <Typography align="center" fontWeight={"700"} color="#CD525B">
            Something went wrong, try again or try later.
          </Typography>
        </Grid>
      )}
      {isGenerating ? (
        <Grid
          xs={12}
          className="createForm"
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <div className="loaderText">Please give us a moment...</div>
          <div className="lds-dual-ring" />
        </Grid>
      ) : (
        <Grid
          xs={12}
          className="marginCenter"
          container
          flexDirection="row"
          spacing={4}
        >
          <DeleteRequirementDialog
            open={showDeleteRequirementDialog}
            requirementLabel={reqLabelToDelete}
            onCancel={onCloseDeleteRequirementDialog}
            onConfirm={onConfirmOutlineRequirementDelete}
          />

          <Grid item xs={4}>
            <InputLabel id="select-llm-model">LLM Model</InputLabel>
            <Select
              label="LLM Model"
              labelId="select-llm-model"
              fullWidth
              disabled
              style={{ height: 40, marginBottom: 15 }}
              onChange={(e) => setLLModel(e.target.value)}
              value={LLMModel}
            >
              <MenuItem value="llama3">Llama3</MenuItem>
              <MenuItem value="mistral">Mistral</MenuItem>
              <MenuItem value="openAI">OpenAI</MenuItem>
            </Select>
            <section className="pdfKeywordSection">
              <div className="responseOutlineHeaderContainer">
                <h3>Response Outline</h3>
                <Button
                  variant="contained"
                  onClick={onGenerateResponse}
                  disabled={isGenerating}
                >
                  Generate
                </Button>
              </div>

              <ul>
                {proposalDetails?.outline?.sections?.map((item) => (
                  <li className="keywordSectionHeader" key={item.header}>
                    <div>
                      <span>{item.header}</span>
                      <Tooltip title="Add Requirement">
                        <IconButton
                          disabled={
                            reqLabelToEdit ||
                            isGenerating ||
                            activeEditedSection ||
                            (activeEditedSection &&
                              activeEditedSection !== item.header)
                          }
                          className="icon editIcon"
                          onClick={() => setActiveEditedSection(item.header)}
                        >
                          <ADD_CIRCLE_ICON />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <ul>
                      {item.header === activeEditedSection && (
                        <div className="keywordSectionItem">
                          <TextareaAutosize
                            minRows={4}
                            autoFocus
                            placeholder="Enter new requirement..."
                            value={newRequirementText}
                            className={clsx("keywordTextArea", {
                              textAreaError: !newRequirementText,
                            })}
                            onChange={(e) =>
                              setNewRequirementText(e.target.value)
                            }
                          />
                          <Tooltip title="Update">
                            <IconButton
                              onClick={onAddNewRequirement}
                              className="icon editIcon"
                              disabled={!newRequirementText}
                            >
                              <CHECK_ICON />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel">
                            <IconButton
                              onClick={onResetNewRequirementText}
                              className="icon closeIcon"
                            >
                              <CLOSE_ICON />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                      {item.requirements.map((req) => (
                        <li
                          key={req.text_source}
                          className="keywordSectionItem"
                        >
                          {req.label === reqLabelToEdit ? (
                            <>
                              <TextareaAutosize
                                type="text"
                                autoFocus
                                value={editedLabel}
                                onChange={onChangeOutlineLabel}
                                className={clsx("keywordTextArea", {
                                  textAreaError: !editedLabel,
                                })}
                              />
                              <Tooltip title="Update">
                                <IconButton
                                  onClick={onUpdateOutlineLabel}
                                  className="icon editIcon"
                                  disabled={!editedLabel}
                                >
                                  <CHECK_ICON />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Cancel">
                                <IconButton
                                  onClick={onResetLabelToEdit}
                                  className="icon closeIcon"
                                >
                                  <CLOSE_ICON />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <span
                                onClick={() =>
                                  onKeywordSelected(req.text_source)
                                }
                              >
                                {req.label}
                              </span>
                              <Tooltip title="Edit Label">
                                <IconButton
                                  onClick={() => onSetReqLabelToEdit(req.label)}
                                  disabled={
                                    reqLabelToEdit ||
                                    isGenerating ||
                                    activeEditedSection
                                  }
                                  className="icon editIcon"
                                >
                                  <EDIT_ICON />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Label">
                                <IconButton
                                  onClick={() =>
                                    onDeleteOutlineRequirement(req.label)
                                  }
                                  disabled={
                                    reqLabelToEdit ||
                                    isGenerating ||
                                    activeEditedSection
                                  }
                                  className="icon deleteIcon"
                                >
                                  <DELETE_ICON />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          </Grid>
          <Grid item xs={8}>
            <Grid xs={12} container>
              <Grid item xs={12}>
                <p>
                  Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"}
                </p>
              </Grid>

              <Grid container xs={12} spacing={1}>
                <Grid item>
                  <Button variant="outlined" onClick={onBackToUpload}>
                    Upload new file
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={pageNumber <= 1}
                    onClick={onGoToPreviousPage}
                    variant="contained"
                  >
                    Previous
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={pageNumber >= numPages}
                    onClick={onGoToNextPage}
                    variant="contained"
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <div className="spacer20" />
            {acceptedFiles && (
              <Grid
                container
                className="fadePanel"
                style={{ overflow: "scroll" }}
              >
                <Document
                  inputRef={pdfDocumentRef}
                  file={acceptedFiles[0]}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={pageNumber}
                    renderAnnotationLayer={false}
                    customTextRenderer={textRenderer}
                    onLoadSuccess={onPageLoadSuccess}
                  />
                </Document>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
