import React from "react"
import { Grid, Typography } from "@mui/material"
import FooterLogo from "../assets/images/Saic-Logo-Blue.png"

const Footer = () => {
  return (  
      <Grid container xs={12} >
        
          <Grid item xs={6} >
        
          <img src={FooterLogo} alt="SAIC" />
         </Grid>

         <Grid item xs={6} >
          <Typography variant="h6"  >
            AI Proposal Generator
          </Typography>
         
        </Grid>

      </Grid>

  )
}

export default Footer
