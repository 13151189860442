import React from "react";
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function RichTextEditorConfirmationModal({
  handleClose,
  onConfirm,
  originalText,
  modifiedText,
}) {
  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="delete-outline-requirement"
      aria-describedby="confirm-outline-requirement-deletion"
    >
      <DialogTitle>Apply Recommended Changes ?</DialogTitle>
      <DialogContent>
        <b>Confirming will replace the following:</b>
        <br />
        <blockquote>{originalText}</blockquote>

        <b> With: </b>
        <br />
        <blockquote>{modifiedText}</blockquote>

        <DialogActions>
          <Button variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
