import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, TextField, Button } from "@mui/material/";
import { useForm, Controller, get } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { UPLOAD_ICON } from "../assets/icons/IconList";

import { postResponseOutline } from "../service/services";

import "react-pdf/dist/esm/Page/TextLayer.css";

export default function CreateProposalContent() {

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [dataFiles, setDataFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [proposalId, setProposalId] = useState("PON9303022");
  const [error, setError] = useState(null);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "application/pdf": [],
        "application/json": [],
        "text/txt": [],
        "application/docx": [],
      },
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <span key={file.path} style={{ color: "#006BB6" }}>
        &nbsp; {file.path} - {file.size} bytes
      </span>
    );
  });

  const handleUploadFiles = async () => {
    if (error) setError(null);
    let formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    setIsLoading(true);

    try {
      const response = await postResponseOutline(formData)

      setDataFiles(response.data);
      navigate("/proposalResponse", {
        state: {
          acceptedFiles,
          proposalDetails: response.data,
        },
      });
    } catch (e) {
      console.log(e);
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProposalId = (event) => {
    console.log(event.target.value);
    setProposalId(event.target.value);
  };

  return (
    <Grid container xs={12} className="createProposalsContainer">
      <Grid item xs={12} className="pageTitle">
        Upload RFI
      </Grid>

      <Grid item className="createForm" container>
        {isLoading ? (
          <Grid
            xs={12}
            className="marginCenter"
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <div className="loaderText">Please give us a moment...</div>
            <div className="lds-dual-ring" />
          </Grid>
        ) : (
          <Grid xs={12}>
            {/* <Grid item xs={6} className="marginCenter">
              <Typography variant="h6">Proposal ID</Typography>
              <div className="spacer10" />
            </Grid>
            <Grid item xs={6} className="marginCenter">
              <TextField
                fullWidth
                variant={"outlined"}
                placeholder={"Proposal ID"}
                name="proposalId"
                onChange={(e) => setProposalId(e.target.value)}
                // {...register("proposalId")}
              />
            </Grid> */}

            <div className="spacer50" />
            {error && (
              <Typography
                align="center"
                fontWeight={"700"}
                color="#CD525B
              "
              >
                Something went wrong, try again or try later.
              </Typography>
            )}
            <Grid
              xs={6}
              style={{
                width: "100%",
                margin: "0 auto",
                border: "dashed 2px #5ab3a8",
                borderRadius: "5px",
                padding: "25px",
                textAlign: "center",
                cursor: "pointer",
              }}
              className="dragDropArea css-selector"
            >
              <div
                {...getRootProps({ className: "dropzone" })}
                style={{ marginTop: "10px" }}
              >
                <input {...getInputProps()} />

                <div className="floating">
                  <UPLOAD_ICON color={"#006bb6"} size={"150px"} />
                </div>

                <Typography style={{ color: "#006bb6" }} variant="h6">
                  Click to upload or drag and drop
                </Typography>
                <div className="spacer20" />

                <Typography
                  sx={{ fontStyle: "italic" }}
                  variant="subtitle2"
                  style={{ color: "#999" }}
                >
                  (Only image and document files will be accepted)
                </Typography>
              </div>
              <div className="spacer20" />
              <Typography style={{ color: "#adf0ff" }}>
                {acceptedFileItems}
              </Typography>
            </Grid>

            <div className="spacer30" />

            <Grid item xs={6} className="marginCenter">
              <Button variant="contained" onClick={handleUploadFiles}>
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
        {/* End of Create Form  */}
      </Grid>
      {/* End of Create Form  */}
    </Grid>
  );
}
