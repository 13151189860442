import React from "react";
import { Grid } from "@mui/material/";
import CreateProposalContent from "./content/createProposalContent";

export default function CreateProposal() {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12}>
      <Grid item xs={12} className="marginCenter">
        <CreateProposalContent />
        <div className="spacer50" />
      </Grid>
    </Grid>
  );
}
